/* marquee */

.marquee {
  width: 100%;
  /* border: 1px solid red; */
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.marquee .containers{
  display: inline-block;
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(calc(-270% - 10px));
  }
}

/* vertical marquee */

.vertical-marquee {
  height: 30rem; /* Set the height of your marquee */
  overflow: hidden;
  position: relative;
}

.vertical-marquee .skill {
  display: block;
  animation: marqueeVertical 10s linear infinite;
  margin-bottom: 10px; /* Add a small gap between containers */
}

@keyframes marqueeVertical {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-150% - 10px)); /* Adjusted to include the gap */
  }
}


/* vertical marqueeReverse */

.vertical-marquee-reverse {
  height: 30rem; /* Set the height of your marquee */
  overflow: hidden;
  position: relative;
}

.vertical-marquee-reverse .skill {
  display: block;
  animation: marqueeVerticalReverse 10s linear infinite;
  margin-bottom: 10px; /* Add a small gap between containers */
}

@keyframes marqueeVerticalReverse {
  0% {
    transform: translateY(-100px); /* Start from the top */
  }
  100% {
    transform: translateY(calc(100% - 118px)); /* Move downwards to the bottom */
  }
}
/* Transitions */

@keyframes TransitionFromTop {
  from {
      opacity: 0;
      transform: translateY(-100px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes TransitionRight {
  from {
      opacity: 0;
      transform: translateX(-100px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes TransitionLeft {
  from {
      opacity: 0;
      transform: translateX(100px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes TransitionFromButtom {
  from {
      opacity: 0;
      transform: translateY(100px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

  
@keyframes TransitionRightLess {
  from {
      opacity: 0;
      transform: translateX(-50px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}


.trans-from-left-less {
  animation: TransitionRightLess 1s;
}

.trans-from-left {
  animation: TransitionRight 1s;
}

.trans-from-right {
  animation: TransitionLeft 1s;
}

.trans-from-buttom {
  animation: TransitionFromButtom 1s;
}

.trans-from-top {
  animation: TransitionFromTop 1s;
}


/* Animista */

.tracking-in-expand {
	-webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.flip-in-hor-bottom {
  -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: .5s;
}

@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}


/* random Color  */
@-webkit-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}
@keyframes rainbow {
  0%{background-position:0% 82%}
  50%{background-position:100% 19%}
  100%{background-position:0% 82%}
}




@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #0ecfa2;
  transform-origin: 0%;
}

@media (max-width: 820px) { 
  .vertical-marquee {
    width: 10rem;
  }

  .vertical-marquee-reverse {
    width: 10rem;

  }
}