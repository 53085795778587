.containers {
    height: 15rem;
    width: 30rem;
    background-color: rgb(55, 77, 55);
    margin: 0 1rem;
    border-radius: 20px;
}

.ft-content {
    display: flex;
    justify-content: flex-end;
    padding-top: 12.6rem;
}

.ft-content a {
    background-color: white;
    padding: .5rem 1rem;
    border-top-left-radius: 20px;
    text-decoration: none;
    font-family: 'Dela Gothic One', cursive;
    font-weight: 300;
    color: black;
    transition: all .2s;
}

.ft-content a:hover {
    color: rgb(249, 203, 111);
}


/* Mobile Styles */

@media (max-width: 820px) {
    .containers {
        height: 20rem;
    }

    .ft-content {
        padding-top: 17.6rem;

    }
}