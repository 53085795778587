.about-me {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(246, 246, 246);
    border: 10px solid rgba(0, 0, 0, 0.048);
    border-radius: 10px;
    transition: all .4s;
}

.about-me:hover {
    transform: scale(1.05);
}

.about-content {
    width: 25rem;

    margin: 2rem;

    text-align: justify;
}

.profile-img {
    width: 25rem;
    height: 24rem;
    border-radius: 30rem;
    border: 5px solid black;
    background: linear-gradient(124deg, #f95a42, #e81d1d, rgb(158, 158, 237), #fbfd83, #6af281, #1ddde8, rgb(234, 200, 200), #e4a0eb, #585558);
   background-size: 1800% 1800%;
   -webkit-animation: rainbow 18s ease infinite;
   -z-animation: rainbow 18s ease infinite;
   -o-animation: rainbow 18s ease infinite;
   animation: rainbow 18s ease infinite;

}

.ab-dot {
    font-size: 3rem;
    position: relative;
    margin-left: -.3rem;
    color: #f9cb6f;
}
/* .img-frame {
    border: 30px solid rgb(35, 34, 34);
    width: fit-content;
    margin: 2rem;

} */

.lets-connect {
    padding-left: 3rem;
    padding-right: 3rem;
    margin: 1rem 0;
    border: 2px solid transparent;
    transition: all .2s;
}

.lets-connect:hover {
    background-color: transparent;
    border: 2px solid black;
}

@media (max-width: 820px) {
    .about-me  {
        transform: scale(0.85);
    }

    .about-me:hover {
        transform: scale(0.9);
    }
    
}