.publication-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;
}
.publication-header {
    width: 30rem;
    padding: 0 2rem;
}

.publication-header h1 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 500;
    color: rgb(107, 107, 107);
}

.pub-img {
    border: dashed 2px #000;/*optional*/

    /* the initial value of border-style is "none", so it must be set to a different value for the border-image to show*/
    border-style: dashed;
  
    /* Individual border image properties */
    border-image-source: url("https://i.stack.imgur.com/wLdVc.png");
    border-image-slice: 2;
    border-image-repeat: round;
  
    /* or use the shorthand border-image */
    border-image: url("https://i.stack.imgur.com/wLdVc.png") 2 round;
    transform: rotate(-3deg);
    transition: all .8s;
    cursor: pointer;
}

.pub-img:hover {
    transform: rotate(0deg);
}

.largeGaps {
    border-image-source: url("https://i.stack.imgur.com/LKclP.png");
    margin: 1rem 10px;
  }

.pub-img img {
    width: 25rem;
}

.publication-header p {
    text-align: justify;
}

.read-article {
    margin-top: 1rem;
    border: 2px solid transparent;
    transition: all .2s;
}

.read-article a {
    text-decoration: none;
    color: black;

}

.read-article:hover  {
    background-color: transparent;
    border: 2px solid black;
}


/* Mobile Styles */
@media (max-width: 820px) {
    .pub-img {
        transform: none;
    }
    .pub-img img {
        width: 100%;

    }

    .publication-header  {
        padding: 0 .5rem;
    }
}