@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* 
  rgb(249, 203, 111) yellow
*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Dela Gothic One', cursive; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(255, 255, 255);
    /* background-image: url("./assets/rippednotes.jpg"); */
    /* background-repeat: repeat; */
    /* background-size: 15vw; */
    

    background-color: #e5e5f7;
    background-image: radial-gradient(#838383 1.5px, #f8f8d156 0.5px);
    background-size: 60px 60px;
/* background-image: radial-gradient(#444cf7 1.4500000000000002px, #e5e5f7 1.4500000000000002px); */

  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: none;
}