.hack-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.hack-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.hack-card {
  padding: .5rem;
  display: flex;
  align-items: center;
  width: 25rem;
  margin: 1rem;
  border: 2px solid transparent;
  transition: all .5s;
  cursor: pointer;
  border-radius: 10px;
}
.hack-icon {
  height: 7rem;
  width: 7rem;
  border-radius: 10px;
}

.hack-content {
  padding: 0 2rem;
}
.hack-content h2 {
  font-size: 1.2rem;
  font-weight: 500;
}

.hack-content p {
  font-weight: 700;
  margin-top: -1rem;
}

.hack-card:hover {
  border: 2px solid rgba(116, 116, 116, 0.199);
  /* border-color: white; */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  transform: scale(1.05);
}

