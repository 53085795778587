.son-image {
    height: 15rem;
    position: relative;
    /* margin-top: 1rem; */
    margin-bottom: -1rem;
}

.hero-body h1 {
    font-family: 'Dela Gothic One', cursive;
    font-size: 3.5rem;
    font-weight: 200;
    color: rgb(28, 37, 40);
}

.hero-body {
    width: fit-content;
}

.other-contents { 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.hero-action-btn {
    display: flex;
}

.abt-btn {
    /* font-family: 'Dela Gothic One', cursive; */
    /* background-color: rgb(249, 203, 111); */
    padding: 1rem 2rem;
    border: 1px solid black;
    border-radius: 30px;
    width: fit-content;
    cursor: pointer;
}

.ot-btn {
    margin-left: 1rem;
    cursor: pointer;
}


@media (max-width: 820px) {
    .hero-body h1 {
        font-size: 2.5rem;
        font-weight: 200;
    }

    .other-contents  {
        display: block;
    }

    .hero-action-btn {
        display: flex;
        margin: 1rem 0;
        margin-left: -1.5rem;
    }
}