.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 10rem;
}


.footer-nav-item {
  display: flex;
  list-style: none;
}

.footer-item {
  margin: 0 1rem;
}

.footer-item img {
  height: 2rem;
}

/* Mobile Styles */
@media (max-width: 820px) {
  .footer-container {
    padding: 1.5rem 1rem;
  }
}