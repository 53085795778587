.company-header {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.exp-compnany-url {
  height: 2rem;
  margin-right: 1rem;
}


.experience-card {
  width: 60rem;
}

.comp-exp {
  margin: 2rem 0;
  padding: 0 1rem;
}


/* Mobile Styles */
@media (max-width: 820px) {
  .experience-card {
    width: 100%;
    padding: 0;
  }
}