
.ft-component {
    color: white;
}

.certification {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 5rem 0;
    font-family: 'Dela Gothic One', cursive;
}

.badges {
    display: flex;
    align-items: center;
}

.badges img {
    margin: 2rem 2rem;

}

.badge-saa {
    height: 8.5rem;
}

.badge-cka {
    height: 10rem;
}

.badge-ckad {
    height: 9rem;
}

.metric-header {
    display: flex;
    justify-content: center;
}

.num-ft {
    width: 10rem;
    margin: 0 2rem;
    padding: 1rem;
    text-align: center;
}
.num-ft h1 {
    font-weight: 800;
    color: rgb(207, 207, 207);
}

.num-ft p {
    font-family: 'Dela Gothic One', cursive;
    font-weight: 100;
}