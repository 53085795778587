.sponsor-container {
  /* background-color: rgb(36, 36, 36); */
  background: url("https://img.freepik.com/free-vector/gradient-black-background-with-wavy-lines_23-2149151738.jpg?size=626&ext=jpg&ga=GA1.1.1141335507.1718150400&semt=ais_user");
  background-size: cover;
  color: white;
  border-radius: 10px;
  padding: 2rem;
  height: 30rem;
  width: 25rem;
}

.sponsor-text {
  color: rgb(188, 188, 188);
  margin-top: 2rem;
}


.sponsor-me-btn {
  --b: 2px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: #ffffff;
  
  padding: calc(.8em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  border: 0;
  width: 100%;
  margin-top: 4rem;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.sponsor-me-btn:hover,
.sponsor-me-btn:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.sponsor-me-btn:active {
  background: var(--color);
  color: #fff;
}

/* Mobile Styles */
@media (max-width: 820px) {
  .sponsor-container {
    width: 100%;
  }
}