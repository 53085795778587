.header {
    padding: 1rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-logo {
    height: 2.5rem;
    transition: all 1s;
    cursor: pointer;
}

.header-logo:hover {
    transform: rotate(90deg);
}

.right-header ul {
    display: flex;
    align-items: center;
    list-style: none;
}

.right-header li {
    margin-left: 4rem;
    cursor: pointer;
}

.yellow-btn {
    font-family: 'Dela Gothic One', cursive;
    background-color: #f9cb6f;
    padding: 1rem 2rem;
    border-radius: 30px;
    width: fit-content;
    cursor: pointer;
}

.mobile-right-header {
    display: none;
}

@media (max-width: 820px) {
    .header {
      padding: 1rem;
      /* margin-bottom: .5rem; */
    }
    .right-header {
        display: none;
    }

    .mobile-right-header {
        display: contents;
    }

    .yellow-btn {
        padding: .8rem 2rem;
    }
  }