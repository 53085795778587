.item {
    /* background-color: red; */
    border-radius: 10px;
    border: 1px solid rgb(136, 136, 136);
    height: 8rem;
    width: 8rem;
    margin: 1rem;
    padding: 1rem;
    cursor: pointer;
    filter: grayscale(70%);
    transition: all .5s;

}


.item:hover {
    filter: grayscale(0%);
}

.skill-item-img {
    height: 3rem;
    /* width: 3rem; */
    margin: .1rem 0;
    border-radius: 5px;
}

.skill-title {
    color: rgb(181, 181, 181);
    padding: 0 .2rem;
    font-size: .9rem;

}

.skill-category {
    padding: 0 .2rem;
    font-size: .9rem;

    /* margin-top: -.4rem; */
    color: rgb(154, 154, 154);
}

@media (max-width: 820px) {
}