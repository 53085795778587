.hero-section {
    padding: 0 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;
    
}

.scroll-to-top {
    z-index: 10;
    position: fixed;
    top: 92vh;
    right: 2rem;
    cursor: pointer;
}

.up-icon {
    height: 3rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 3rem;
    transition: all .5s;
    border: 1px solid black;
}

.up-icon:hover {
    transform: scale(1.09);
}

.ronaldo-image {
    mix-blend-mode: multiply;

}

.mrq-highlights {
    margin-top: 2rem;
}

.featured-section {
    /* margin-top: 15rem; */
    padding: 2rem 0;
    background-color: rgb(22, 30, 33);
    /* height: 32rem; */
}

.about-me-section {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
}

.skill-section {
    display: flex;
    justify-content: center;
    background-color: rgb(15, 15, 15);
    flex-wrap: wrap;
}

.skill-header {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 8rem;
    background-image: url("../../assets/ghost.png");
    background-position-x: -10rem;
}

.skill-container {
    display: flex;
    /* background-color: rgb(22, 30, 33); */
    height: 30rem;
    color: white;
    width: fit-content;
}

.skill-tabs {
    display: flex;
}

.v-marquee {
    background-color: rgb(139, 148, 139);
    height: 20rem;
}

.marquee-content {
    max-height: fit-content;
    position: relative;
    /* margin-top: 10rem; */
    width: 30rem;
}

.marquee-content-2 {
    max-height: fit-content;
    position: relative;
    /* margin-top: -10rem;
    margin-left: 10rem; */
    width: 30rem;
}

.marquee-content-3 {
    max-height: fit-content;
    /* position: relative;
    margin-top: -10rem;
    margin-left: 20rem; */
    width: 30rem;
}

.pub-section-header {
    text-align: center;
    margin: 2rem 0;
    color: rgb(250, 192, 75);
    color: rgba(255, 255, 255, 0);
    font-family: 'Poppins', sans-serif;
}

.pub-section-header h1 {
    -webkit-text-stroke: 1px #000000;
    font-size: 3rem;
}

.publication-section {
    display: flex;
    justify-content: center;
    padding: 0 3rem;
}

/* .marquee {
    width: 30rem;
} */

.hackathon-header,
.experience-header,
.blog-header,
.projects-header {
    margin-top: 4rem;
    text-align: center;
}

.hackathon-header {
    font-size: 1.5rem;
    margin-top: 8rem;
}

.red-dot {
    font-size: 5rem;
    position: relative;
    margin-left: -.5rem;
    color: rgb(231, 81, 81);
}

.experience-header {
    margin-bottom: 5rem;
    color: white;
}

.experience-header h1 {
    font-size: 3rem;
    -webkit-text-stroke: 2px #000000;
    font-size: 10rem;
    text-transform: uppercase;
}

.experience-section-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -5rem;
    /* padding: 0 10%; */
}

.football-ground-section {
    display: flex;
    justify-content: center;
    margin-top: 10rem;
}

.post {
    height: 15rem;
    width: 40rem;
    border-top-left-radius: 5px;
    border-top: 5px solid black;
    border-left: 5px solid black;
    border-right: 5px solid black;
    position: relative;
    margin-top: 3rem;
    margin-right: -15rem;
    background-image: url("https://soccerworld.com.au/cdn/shop/products/STANDARD-NET.png?v=1690416450&width=533");
    background-size: 10rem;
}

.bolt-img {
    position: relative;
    z-index: 5;
    height: 5rem;
    margin-top: 6rem;
    margin-right: -4rem;
}

.messi-img {
    margin-top: -12rem;
    height: 30rem;
    position: relative;
}

.connect-background {
    /* background-color: rgb(42, 42, 42); */
    background: url("https://img.freepik.com/free-vector/gradient-black-background-with-wavy-lines_23-2149151738.jpg?size=626&ext=jpg&ga=GA1.1.1141335507.1718150400&semt=ais_user");
    background-size: cover;
    filter: brightness(100%);
    height: 35rem;
    width: 100%;
    border-top: 10px solid rgb(100, 178, 100);
    margin-top: -0.3rem;
}

.connect-background {
    text-align: center;
    /* margin: 2rem 0; */
    color: rgb(250, 192, 75);
    color: rgba(255, 255, 255, 0);
    font-family: 'Poppins', sans-serif;
    padding: 6rem 0;
    /* margin-top: 10rem; */
}

.connect-background h1 {
    -webkit-text-stroke: 1px #ffffff;
    font-size: 10rem;
    text-transform: uppercase;

}

.connect-with-sections {
    padding: 0 15%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -18rem;
    margin-bottom: 10rem;
}

.get-in-touch-container {
    background-color: white;
    /* background-color: rgb(236, 236, 236); */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    display: flex;
    padding: .8rem;
    border-radius: 14px;
}



/* Mobile Styles */

@media (max-width: 820px) {
    .hero-section {
        padding: 0 5%;
        margin-top: 6rem;
    }

    .connect-background h1 {
        font-size: 6rem;
    }

    .connect-with-sections {
        padding: 0 5%;
        margin-top: -21.8rem;
    }

    .get-in-touch-container {
        display: block;
    }

    .skill-header {
        padding: 10rem 2rem;
    }

    .skill-tabs {
        border-top: 2px solid rgba(255, 255, 255, 0.358);
        /* margin-top: 2rem; */
    }

    .skill-section {
        display: block;
    }

    .skill-container {
        display: block;
        height: auto;
        width: 100%;
    }

    .experience-header h1 {
        font-size: 5.2rem;
    }
    
    .experience-section-container {
        padding: 0 4%;
    }
}

@media (min-width: 1250px) {
    .get-in-touch-container {
        width: 60rem;
    }
}